import axios from 'axios';
import Cookies from 'js-cookie';
import { eventFb, fbeventConstants } from '../../components/facebook-pixel/pixel-helpers';
import { eventMixPanel, identifyMixPanel, mixPanelEventConstants } from '../../components/mix-panel-tracker/mixpanel-helpers';
import { rolesConstants } from '../../constants/roles.constants';
import { routeConstants } from '../../routes';
import sendRequest from '../../utils/useAxios';
import { setBetaCode } from '../beta-code/beta.actions';
import { GET_CUSTOMER, GET_IS_CUSTOMER } from '../customer/customer.api';
import { createIntegration } from '../integrations/integrations.actions';
import { isPro } from '../widget/widget.selectors';
import { CREATE_SETTINGS_SIGNUP, GET_USER_PROFILE, UPDATE_USER_PROFILE, UPLOAD_PROFILE_IMAGE, USER_CHANGE_PASSWORD, USER_LOGIN, USER_PASSWORD_RESET_EMAIL, USER_REGISTER, VERIFY_USER_PROFILE } from './user.api';
import { userTypes } from './user.types';
import { encryptPassword } from '../../utils/app-utils';

export const signInUser = (user, router) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(USER_LOGIN.METHOD, USER_LOGIN.PATH, user);

  if (data?.token?.token) {
    // Migration: Conditions for migrated users
    if (data?.isMigrated === true) {
        Cookies.set('migrated', 'true');

        const secretKey = process.env.NEXT_PUBLIC_MIGRATION_SECRET_KEY || '';
        // Encrypt password to send to V2 page 
        const encryptedPassword = encryptPassword(user?.password, secretKey);
      
        return router.push(`${process.env.NEXT_PUBLIC_V2_APP_URL}/new-version-redirect?email=${user?.email}&password=${encryptedPassword}` ?? '' );
    }else{
        Cookies.remove('migrated');
    }

    if (router?.query?.redirect) {
      router.push(`${process.env.NEXT_PUBLIC_SUPPORT_WEBSITE}?token=${JSON.stringify({ email: user?.email, token: data.token.token })}`)
    }
    dispatch(setToken(data.token.token));
    dispatch(setToken(data.token.token));

    dispatch(getUser(router));
    dispatch(setErrorMessages([]));
    if (router?.query?.code) {
      router.push(`${process.env.NEXT_PUBLIC_CLIENT_URL}/jobberRedirect?code=${router?.query?.code}`)
    }
  } else if (!data?.token?.token) {
    if (data.statusCode && data.statusCode == 401) {
      dispatch(setErrorMessages([data.message]));
    } else if (data.statusCode && data.statusCode == 400) {
      dispatch(setErrorMessages([...data.message]));
    } else if (!data.statusCode && data.status == 401) {
      dispatch(setErrorMessages([data.error]));
    }
    dispatch(setLoading(false));
  }
};
export const signUpUser = (user, router) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrorMessages([]));

  const { data } = await sendRequest(USER_REGISTER.METHOD, USER_REGISTER.PATH, user);
  if (!data.statusCode && data?.data && data?.data[0].statusCode == 202) {
    localStorage.removeItem("PRICING_PLAN")
    eventFb(fbeventConstants.COMPLETE_REGISTRATION)
    eventMixPanel(mixPanelEventConstants.COMPLETE_REGISTRATION)
    identifyMixPanel(user?.email)
    await sendRequest(CREATE_SETTINGS_SIGNUP.METHOD, CREATE_SETTINGS_SIGNUP.PATH, { userId: data.customer.user })
    router.push('/email/check-email-verification');
  } else if (data.statusCode && data.statusCode == 400) {
    dispatch(setBetaCode({ code: "", visible: true }))
    dispatch(setErrorMessages(typeof data.message == 'string' ? [data.message] : [...data.message]));
  } else if (data.statusCode && data.statusCode == 406) {
    dispatch(setErrorMessages(typeof data.message == 'string' ? [data.message] : [...data.message]));
  }
  dispatch(setLoading(false));
};

export const sendEmailToSeniorWorkers = (payload: any) => async (_dispatch) => {
  const { data: workers } = await sendRequest('GET', '/profile/senior-workers')
  if (workers && workers.length) {
    sendRequest('POST', '/profile/help-email', { workers })
  }
}

export const setErrorMessages = (errorMessages) => (dispatch) => {
  dispatch({
    type: userTypes.SET_USER_ERROR,
    payload: errorMessages,
  })
}

export const changePassword = (password, confirmPassword, token, router) => async (dispatch) => {
  dispatch(setLoading(true));
  if (password === confirmPassword) {
    const { data } = await sendRequest(USER_CHANGE_PASSWORD.METHOD, `${USER_CHANGE_PASSWORD.PATH}/${token}`, { password });
    if (data && !data.statusCode) {
      dispatch(setErrorMessages([]));
      router.push('/password-reset/success');
    } else {
      dispatch(setErrorMessages([data.message]));
    }
  } else {
    dispatch(setErrorMessages(['Passwords must match']));
  }
  dispatch(setLoading(false));
}

export const setUserEmail = (email) => (dispatch) => {
  dispatch({
    type: userTypes.SET_EMAIL,
    payload: email,
  })
}

export const sendPasswordResetEmail = (email, router) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(USER_PASSWORD_RESET_EMAIL.METHOD, `${USER_PASSWORD_RESET_EMAIL.PATH}/${email}`);
  if (data[0] && (data[0].statusCode === 200 || data[0].statusCode === 202)) {
    router.push('/password-reset/send-email');
  } else {
    dispatch(setErrorMessages([data.message]));
  }
  dispatch(setLoading(false));
}

export const setToken = (token) => (dispatch) => {
  dispatch({
    type: userTypes.SET_USER_TOKEN,
    payload: token,
  });
};

export const checkIsCustomer = () => async (dispatch) => {
  const { data } = await sendRequest(GET_IS_CUSTOMER.METHOD, `${GET_IS_CUSTOMER.PATH}`);
  if (data) {
    dispatch(setSuccess(userTypes.IS_CUSTOMER, data));
  }
};

export const getUser = (router) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_USER_PROFILE.METHOD, GET_USER_PROFILE.PATH);
  // const { data: customerData } = await sendRequest(GET_PUBLIC_CUSTOMER.METHOD, `${GET_PUBLIC_CUSTOMER.PATH}/${data._id}`);
  const { data: customerData } = await sendRequest(GET_CUSTOMER.METHOD, GET_CUSTOMER.PATH);
  if (data && !data.statusCode) {
    dispatch(checkIsCustomer());
    if (router?.query?.densityblastercustomerId || router?.query?.densityblasteruserId) {
      const { data: densityBlasterIntegration } = await sendRequest('GET', `/integrations/customer/singleIntegration/${customerData._id}/density-blaster`)
      if (!densityBlasterIntegration) {
        dispatch(createIntegration({
          customerId: customerData._id,
          type: 'density-blaster',
          auth: {},
          data: {
            densityblastercustomerId: router?.query?.densityblastercustomerId,
            densityblasteruserId: router?.query?.densityblasteruserId
          }
        }))

        //body for density blaster
        const body = {
          customerId: router?.query?.densityblastercustomerId,
          type: 'deeplawn',
          auth: {},
          data: {
            dlcustomerId: customerData._id,
            dluserId: customerData.user
          }
        }
        await axios.post(`${process.env.NEXT_PUBLIC_DENSITY_BLASTER_API_URL}/integrations`, body)
      }
    }
    dispatch(setSuccess(userTypes.SET_USER, data));
    if (data.roles.includes(rolesConstants.ROBIN)) {
      router.push(`${routeConstants.ROBIN_SEARCH.basePath}/${data?.owner_profile_id || data?._id}`);
    } else if (data.roles.includes(rolesConstants.ADMIN)) {
      router.push(routeConstants.ADMIN.SEARCH_FEED.pathname);
    } else if (isPro(window)) {
      router.push('/contractor/pro-measurements');
    } else if (data.roles.includes(rolesConstants.SENIORWORKER)) {
      router.push(routeConstants.WORKER.HALLWAY.pathname);
    }
    else if (data.roles.includes(rolesConstants.WORKER)) {
      router.push(routeConstants.WORKER.DASHBOARD.pathname);
    } else if (data.roles.includes(rolesConstants.CONTRACTOR)) {
      router.push(routeConstants.DASHBOARD.pathname);
    } else if (data.roles.includes(rolesConstants.UNPAIDCUSTOMER) && !customerData.subscription) {
      eventFb(fbeventConstants.INITIATE_CHECKOUT)
      eventMixPanel(mixPanelEventConstants.INITIATE_CHECKOUT)
      router.push(routeConstants.PAYMENT_DETAILS.pathname);
    }
    else if (data.roles.includes(rolesConstants.UNPAIDCUSTOMER) && customerData.subscriptionStatus !== 'active') {
      router.push(routeConstants.PRICING.pathname);
    }
    else {
      router.push(routeConstants.DASHBOARD.pathname);
    }
  }
  dispatch(setLoading(false));
};

export const getOnlyUser = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(GET_USER_PROFILE.METHOD, GET_USER_PROFILE.PATH);
  if (data) {
    dispatch(checkIsCustomer());
    dispatch(setSuccess(userTypes.SET_USER, data));
  }
  dispatch(setLoading(false));
};

export const updateProfile = (id: string, name: string, fieldRoutesEmployeeId: string, file?: any) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrorMessages([]));

  let formBody: any = { name, fieldRoutesEmployeeId }
  if (file) {
    const { data } = await sendRequest(UPLOAD_PROFILE_IMAGE.METHOD, UPLOAD_PROFILE_IMAGE.PATH, file);
    if (data && !data.statusCode) {
      formBody = { ...formBody, avatar: data }
    }
  }

  const { data } = await sendRequest(`${UPDATE_USER_PROFILE.METHOD}`, `${UPDATE_USER_PROFILE.PATH}/${id}`, formBody);
  if (data && !data.statusCode) {
    dispatch(setSuccess(userTypes.SET_USER, data));
  } else {
    dispatch(setError([data?.message]));
    setTimeout(() => {
      dispatch(setError([]));
    }, 2000);
  }
  dispatch(setLoading(false));
};

export const updateUser = (userId: string, payload: any) => async (dispatch) => {
  const { data } = await sendRequest(`${UPDATE_USER_PROFILE.METHOD}`, `${UPDATE_USER_PROFILE.PATH}/${userId}`, payload);
  if (data && !data.statusCode) {
    dispatch(setSuccess(userTypes.SET_USER, data));
  }
};

export const updateProfileIntegrations = (integrationsData: any, id: string) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrorMessages([]));

  const { data } = await sendRequest(`${UPDATE_USER_PROFILE.METHOD}`, `${UPDATE_USER_PROFILE.PATH}/${id}`, { integrationsData });
  if (data && !data.statusCode) {
    dispatch(setSuccess(userTypes.SET_USER, data));
  } else {
    dispatch(setError([data?.message]));
    setTimeout(() => {
      dispatch(setError([]));
    }, 2000);
  }
  dispatch(setLoading(false));
};

export const logoutUser = (router) => async (dispatch) => {
  dispatch({
    type: userTypes.SET_INITIAL_STATE,
  });
  localStorage.removeItem("batch-worker")
  localStorage.removeItem("batch-admin")
  router.push('/');
};

export const setLoading = (loading) => async (dispatch) => {
  dispatch({
    type: userTypes.SET_USER_LOADING,
    payload: loading,
  });
};
export const verifyEmailToken = (email, token) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await sendRequest(
    VERIFY_USER_PROFILE.METHOD,
    `${VERIFY_USER_PROFILE.PATH}/${email}/${token}`
  );
  dispatch(setSuccess(userTypes.SET_VERIFICATION_STATUS, data.verified));
  dispatch(setLoading(false));
};

export const getContractor = async (userId) => {
  const { data } = await sendRequest('GET', `/profile/widget-contractor/${userId}`)
  if (data && !data.statusCode) {
    return data;
  }

}

export const setSuccess = (action, payload) => {
  return {
    type: userTypes[action],
    payload,
  }
}

export const setError = (payload) => {
  return {
    type: userTypes.SET_USER_ERROR,
    payload,
  }
}

export const setUserMember = (payload) => {
  return {
    type: userTypes.SET_USER_MEMBER,
    payload,
  }
}
